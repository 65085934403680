<template>
  <div>
    <Nav></Nav>
    <div>

    </div>
        <section>
      <div class=" text-light p-5">
        <h1 class="ltext bt">Parancsok <i class="fas fa-terminal"></i></h1>
        <span class="cmdspan">
          <small>
            <a href="#modcmd" class="norma cmds">Moderálási parancsok</a>&nbsp;&nbsp;<br class="mbr">
            <a href="#funcmd" class="norma cmds">Szórakozás parancsok</a>&nbsp;&nbsp;<br class="mbr">
            <a href="#muscmd" class="norma cmds">Zene parancsok</a>&nbsp;&nbsp;<br class="mbr">
            <a href="#botcmd" class="norma cmds">Bot parancsok</a>
            </small>
            </span><br>
        <br>
        <h2 class="ltext">Prefix: -$</h2><br>
        <h3 class="ltext" id="modcmd">Moderálási parancsok</h3>

          <div class="parancs">
             <b>Kitiltás</b><br>
             <label>-$</label>
             <label>ban @felhasználó indok</label>
          </div><br>

          <div class="parancs">
             <b>Kirúgás</b><br>
             <label>-$</label>
             <label>kick @felhasználó indok</label>
          </div><br>

          <div class="parancs">
             <b>Összes rang listázása</b><br>
             <label>-$</label>
             <label>ranglista</label>
          </div><br>

          <div class="parancs">
             <b>Felhasználó információi</b><br>
             <label>-$</label>
             <label>taginfo @felhasznalo</label>
          </div><br>

          <div class="parancs">
             <b>Felhasználó figyelmeztetése</b><br>
             <label>-$</label>
             <label>warn @felhasználó indok</label>
          </div><br>

          <div class="parancs">
             <b>Felhasználó figyelmeztetéseinek megtekintése</b><br>
             <label>-$</label>
             <label>warnlist @felhasználó</label>
          </div><br>

          <div class="parancs">
             <b>Felhasználó figyelmeztetésének törlése<br></b><small>(a ministringet a -$warnlist paranccsal tekintheted meg)</small><br>
             <label>-$</label>
             <label>warndel ministring</label>
          </div><br>

          <div class="parancs">
             <b>Üzenetek törlése</b><br>
             <label>-$</label>
             <label>clear mennyiség</label>
          </div><br>

          <div class="parancs">
             <b>Csatorna lassítása</b><br>
             <label>-$</label>
             <label>lassitas idő (mp-ben)</label>
          </div><br>

          <h3 class="ltext" id="funcmd">Szórakozás parancsok</h3>

          <div class="parancs">
             <b>Keresés a neten</b><br>
             <label>-$</label>
             <label>kereses (pl.:google) keresés szövege</label>
          </div><br>

          <div class="parancs">
             <b>Egy random válasz a kérdésedre</b><br>
             <label>-$</label>
             <label>8ball kérdés</label>
          </div><br>

          <div class="parancs">
             <b>Egy tag megölelése</b><br>
             <label>-$</label>
             <label>oleles @felhasznalo</label>
          </div><br>

          <div class="parancs">
             <b>Egy random meme küldése</b><br>
             <label>-$</label>
             <label>meme</label>
          </div><br>

          <div class="parancs">
             <b>Egy random nsfw gif küldése</b><br>
             <label>-$</label>
             <label>nsfw</label>
          </div><br>

          <h3 class="ltext" id="muscmd">Zene parancsok</h3>

          <div class="parancs">
             <b>Lejátszás</b><br>
             <label>-$</label>
             <label>lejatszas zene címe vagy url-je</label>
          </div><br>

          <div class="parancs">
             <b>Leállítás</b><br>
             <label>-$</label>
             <label>leallitas</label>
          </div><br>

          <h3 class="ltext" id="botcmd">Bot parancsok</h3>

          <div class="parancs">
             <b>Bot információk</b><br>
             <label>-$</label>
             <label>botinfo</label>
          </div><br>

          <div class="parancs">
             <b>A bot jelenlegi pingje</b><br>
             <label>-$</label>
             <label>ping</label>
          </div><br>

          <div class="parancs">
             <b><a href="https://discord.com/oauth2/authorize?client_id=866425040353624074&permissions=8&scope=bot%20applications.commands" class="norma">Bot meghívása</a></b><br>
             <label>-$</label>
             <label>meghivas</label>
          </div><br>

          <div class="parancs">
             <b><a href="https://discord.gg/ycgnEDQeVw" class="norma">Support szerver</a></b><br>
             <label>-$</label>
             <label>support</label>
          </div><br>

          <div class="parancs">
             <b>Hibabejelentés</b><br>
             <label>-$</label>
             <label>hiba leírás a hibáról</label>
          </div><br>

          <div class="parancs">
             <b>Van egy öteleted a bothoz?</b><br>
             <label>-$</label>
             <label>otlet leírás az ötletről</label>
          </div><br>





      </div>
    </section>
  </div>

  <!--<footer>
    <div class="fvg">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path fill="#393c3d" fill-opacity="1" d="M0,96L80,122.7C160,149,320,203,480,202.7C640,203,800,149,960,133.3C1120,117,1280,139,1360,149.3L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
      </svg>
    </div>
    <div class="footerbg">
      <h1 class="ltext">&copy; PhoenixBot</h1>
    </div>
  </footer>-->
  <Footer/>
</template>

<script>
// @ is an alias to /src
import Nav from "../components/Nav.vue";
import Footer from "../components/Footer.vue"

export default {
  name: 'About',
  components: {
    Nav,
    Footer
  }
}
</script>

<style>
.bt {
  font-size: 45px;
}

.centdiv {
  position: absolute;
  width: 350px;
  height: 200px;
  z-index: 10;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -150px;
}

.tabl {
  margin-top: 300px;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(odd) {
  background-color: #dddddd31;
}

#th {
  background: #292b2c;
}

.tabld {
  overflow: auto;
}

.parancs {
  width:100%;
  border: 2px solid #393c3d;
  padding:20px;
  text-decoration: none;
  color:white;
  background: #dddddd31;
}

.norma {
  color:white;
  text-decoration:none;
}

.norma:hover {
  text-decoration:underline;
  color:white;
}

.parancs:hover {
  background: #dddddd46;
  animation-name: animateShadow;
  animation-duration: 0.1s;
  box-shadow: 5px 10px #dddddd46;

}
@keyframes animateShadow {
  0% {box-shadow: 1px 1px #dddddd46;}
  100% {box-shadow: 5px 10px #dddddd46;}
}

.fvg {
  margin-bottom: -70px;
}
footer {
  text-align: center;
  margin-bottom: 0;
}

.footerbg {
  background: #393c3d;
}

.cmds {
  font-size:15px;
  color:#313131;
}
.cmds:hover {
  font-size:15px;
  color:#313131;
}

.mbr {
  display:none;
}

@media(max-width: 800px){
  .mbr{
    display:block;
  }
  .fvg {
    margin-bottom: -20px;
  }
  footer {
    margin-bottom: -20px;
  }
  .footerbg {
    padding: 20px;
    padding-top:30px;
  }

}



@media(max-width: 600px){
  .tabld {
    width: 120%;
    margin-left: -10%;
  }


}



</style>